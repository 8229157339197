import { ReactNode, useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Checkbox, Container, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useTestApiKey from "../hooks/useTestApiKey";
import { apiClient } from "../bootstrapping/InitApiClient";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import { JSONTree } from 'react-json-tree';

const GetApplicationsAwaitingSubmission: React.FC = () => {
  const [applicationsAwaitingSubmission, setApplicationsAwaitingSubmission] = useState<number | null>(null)

  return (
    <Alert severity={applicationsAwaitingSubmission == null ? 'info' : 'success'}>
      <AlertTitle>Applications Awaiting Submission to the DBS</AlertTitle>
      <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
        {applicationsAwaitingSubmission == null ? '...' : applicationsAwaitingSubmission}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <SingleClickWaitingButton
          variant="contained"
          onClick={async () => {
            console.log("Updating the applications awaiting submission to the DBS")
            const applications = await apiClient.getEBulkApplications({ queries: { columnFilters: [{ id: 'status', value: 'awaiting_submission_to_DBS' }] }, headers: {} })
            setApplicationsAwaitingSubmission(applications.meta.totalRowCount)
          }}
        >
          Update
        </SingleClickWaitingButton>
      </Box>
    </Alert>
  )
}

type UseStateWithCheckboxProps = {
  initialState: boolean,
  label: string,
  disabled?: boolean,
}
const useStateWithCheckbox = (props: UseStateWithCheckboxProps): [boolean, ReactNode, (newValue: boolean) => void] => {
  const [state, setState] = useState<boolean>(props.initialState)
  return [
    state,
    (
      <FormControlLabel
        control={
          <Checkbox
            checked={state}
            onChange={(e) => setState(e.target.checked)}
            disabled={props.disabled}
          />
        }
        label={props.label}
      />
    ),
    setState,
  ]
}

const TestConnectionsCheck: React.FC = () => {
  const { testApiKey } = useTestApiKey()
  const [response, setResponse] = useState<any | null>(null)

  const [httpDirect, HttpDirectCheckbox] = useStateWithCheckbox({ initialState: true, label: 'HTTP Direct' })
  const [httpProxy, HttpProxyCheckbox] = useStateWithCheckbox({ initialState: true, label: 'HTTP Proxy' })
  const [ftpConnect, FtpConnectCheckbox] = useStateWithCheckbox({ initialState: true, label: 'FTP Connect' })
  const [ftpList, FtpListCheckbox] = useStateWithCheckbox({ initialState: false, label: 'FTP List' })
  const [ftpDownload, FtpDownloadCheckbox, setFtpDownload] = useStateWithCheckbox({ initialState: false, label: 'FTP Download (requires FTP List)', disabled: !ftpList })
  const [ftpDelete, FtpDeleteCheckbox, setFtpDelete] = useStateWithCheckbox({ initialState: false, label: 'FTP Delete (required FTP Download)', disabled: !ftpDownload })
  const [ftpUpload, FtpUploadCheckbox] = useStateWithCheckbox({ initialState: false, label: 'FTP Upload' })

  useEffect(() => {
    if (!ftpList) setFtpDownload(false)
    if (!ftpDownload) setFtpDelete(false)
  }, [ftpList, ftpDownload, setFtpDownload, setFtpDelete])

  return (
    <Alert severity={response == null ? 'info' : 'success'}>
      <AlertTitle>E-Bulk Connection Check</AlertTitle>
      {response && <JSONTree data={response} />}

      <FormGroup>
        {HttpDirectCheckbox}
        {HttpProxyCheckbox}
        {FtpConnectCheckbox}
        {FtpListCheckbox}
        {FtpDownloadCheckbox}
        {FtpDeleteCheckbox}
        {FtpUploadCheckbox}
      </FormGroup>

      <Box display="flex" justifyContent="flex-end">
        <SingleClickWaitingButton
          variant="contained"
          onClick={async () => {
            console.log("Checking the E-Bulk Connections")
            const response = await apiClient.testConnectionsCheck({
              queries: {
                httpDirect,
                httpProxy,
                ftpConnect,
                ftpList,
                ftpDownload,
                ftpDelete,
                ftpUpload,
              },
              headers: {
                'X-Test-API-Key': testApiKey,
              }
            })
            setResponse(response)
          }}
        >
          List Files
        </SingleClickWaitingButton>
      </Box>
    </Alert>
  )
}

export const Element: React.FC = () => {

  return <>
    <Helmet>
      <title>E-Bulk System</title>
    </Helmet>
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          E-Bulk System
        </Typography>

        <GetApplicationsAwaitingSubmission />

        <Typography component="div" paragraph>
          This section helps walkthrough the following phases:
          <ol>
            <li>Find out how many applications are ready to submit to the DBS (i.e. awaiting_submission_to_DBS)</li>
            <li>Find out the last time when a batch was sent to the DBS (display the result as an absoute timestamp and relative time agp)</li>
            <li>Trigger a batch send (display how many were sent)</li>
            <li>Find out how many application are ready to get responses from the DBS for (i.e. awaiting_response_from_DBS)</li>
            <li>Trigger a batch receive (display how many were received)</li>
          </ol>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Testing
        </Typography>

        <TestConnectionsCheck />
      </Box>
    </Container>
  </>
}

export default Element
