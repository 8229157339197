import { Alert, AlertTitle, Box, Button, Modal, Typography, IconButton, ButtonProps } from "@mui/material"
import { Close as CloseIcon } from '@mui/icons-material'
import { schemas } from "../generated/api/client"
import { z } from "zod"
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { TextFieldElement } from "react-hook-form-mui"
import { useNavigate } from "react-router-dom"

type EBulkApplication = z.infer<typeof schemas.EBulkApplication>

const RejectApplicationButton: React.FC<{ dbsApp: EBulkApplication } & Omit<ButtonProps, 'onclick'>> = ({ dbsApp, children, ...buttonProps }) => {
  const navigate = useNavigate()
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const zLocalFormScheman = z.object({
    reason: z.string(),
  })
  type LocalFormScheman = z.infer<typeof zLocalFormScheman>
  const form = useForm<LocalFormScheman>({
    resolver: zodResolver(zLocalFormScheman),
    mode: 'onBlur',
  })

  const withdrawableStatuses: Array<typeof dbsApp.status> = [
    'draft', 'awaiting_customer_approval', 'awaiting_payment', 'awaiting_countersigning',
  ]
  return withdrawableStatuses.includes(dbsApp.status)
    ? <>
      <Button {...buttonProps} onClick={handleOpen}>{children}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Reject Application
          </Typography>
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>Are you sure?</AlertTitle>
            If you withdraw this application, this action cannot be reversed. The applicant may restart the application and try again.<br/>
            <br/>
            If they have already paid and the application has not yet been submitted to the DBS, they will be given a credit for their restarted application.<br />
            <br />
            <strong>Be sure you want to do this.</strong>
          </Alert>
          <TextFieldElement
            control={form.control}
            name="reason"
            label="Reason"
            fullWidth
          />
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              form.handleSubmit(async (data) => {
                await apiClient.rejectMyEBulkApplicationByID({
                  reason: data.reason,
                  by: 'applicant',
                }, { params: { id: dbsApp.id } })
                queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
                navigate('/')
              })()
            }}
            sx={{ mt: 2 }}
          >
            Reject Application
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleClose}
            sx={{ ml: 3, mt: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
    : null
}

export default RejectApplicationButton
