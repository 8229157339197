import React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import * as Root from './routes/Root';
import * as Index from './routes/Index';
import * as Profiles from './routes/Profiles';
import * as Customers from './routes/Customers';
import * as AddCustomer from './routes/AddCustomer';
import * as Jobs from './routes/Jobs';
import * as AddJob from './routes/AddJob';
import * as Invites from './routes/Invites';
import * as AddInvite from './routes/AddInvite';
import * as Applications from './routes/Applications';
import * as ApplicationCountersign from './routes/ApplicationCountersign';
import * as EBulk from './routes/EBulk';
import * as Testing from './routes/Testing';
import ErrorPage from './components/ErrorPage';
import { queryClient } from './bootstrapping/InitReactQuery';
import './App.css';

const router = () => createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root.Element />}
    >
      <Route errorElement={<ErrorPage />}>
        <Route
          index
          element={<Index.Element />}
        />
        <Route
          path="profiles"
          element={<Profiles.Element />}
        />
        <Route
          path="customers"
          element={<Customers.Element />}
        />
        <Route
          path="customers/add"
          element={<AddCustomer.Element />}
          action={AddCustomer.action(queryClient)}
        />
        <Route
          path="jobs"
          element={<Jobs.Element />}
        />
        <Route
          path="jobs/add"
          element={<AddJob.Element />}
          action={AddJob.action(queryClient)}
        />
        <Route
          path="invites"
          element={<Invites.Element />}
        />
        <Route
          path="invites/add"
          element={<AddInvite.Element />}
          action={AddInvite.action(queryClient)}
        />
        <Route
          path="invites/:id/rescind"
          action={Invites.rescind(queryClient)}
        />
        <Route
          path="applications"
          element={<Applications.Element />}
        />
        <Route
          path="applications/:application_id/countersign"
          element={<ApplicationCountersign.Element />}
        />
        <Route
          path="ebulk"
          element={<EBulk.Element />}
        />
        <Route
          path="testing"
          element={<Testing.Element />}
        />
      </Route>
    </Route>
  )
)

const App: React.FC = () => {
  console.log("[App] Loaded!")
  return <RouterProvider router={router()} />
}

export default App;
